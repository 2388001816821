import i18n from "src/services/i18n";

export const MENU = [
  //#region Permission Management
  {
    title: i18n.t("side_bar.account_management"),
    icon: "user",
    type: "sub",
    active: false,
    children: [
      {
        title: i18n.t("side_bar.user_management"),
        icon: "user",
        type: "link",
        active: false,
        path: "/users",
      },
      {
        title: i18n.t("side_bar.group_management"),
        icon: "users",
        type: "link",
        active: false,
        path: "/groups",
      },
      // {
      //   title: i18n.t("side_bar.admins"),
      //   icon: "user",
      //   type: "link",
      //   active: false,
      //   path: "/admins",
      // },
      {
        title: i18n.t("side_bar.company_management"),
        icon: "briefcase",
        type: "link",
        active: false,
        path: "/companies",
      },
    ],
  },
  //#endregion

  //#region Storage management
  {
    title: i18n.t("side_bar.storage_management"),
    icon: "shopping-bag",
    type: "link",
    active: false,
    path: "/storages",
  },
  //#endregion

  //#region Plant management
  {
    title: i18n.t("side_bar.plan_management"),
    icon: "book",
    type: "link",
    active: false,
    path: "/plans",
  },
  //#endregion

  //#region Taxes
  {
    title: i18n.t("side_bar.currency_vat"),
    icon: "dollar-sign",
    type: "sub",
    active: false,
    // path: "/tax-zones-and-rates",
    children: [
      {
        path: "/currency",
        title: i18n.t("side_bar.currency"),
        type: "link",
      },
      {
        path: "/tax-zones-and-rates",
        title: i18n.t("page_tax_zones_and_rates.page_title"),
        type: "link",
      },
    ],
  },
  //#endregion

  //#region Payement management
  {
    title: i18n.t("side_bar.payment_management"),
    icon: "credit-card",
    type: "link",
    active: false,
    path: "/payments",
  },
  //#endregion

  //#region Gold management
  {
    title: i18n.t("side_bar.gold_management"),
    icon: "star",
    type: "sub",
    active: false,
    children: [
      {
        path: "/gold-management/transaction-history",
        title: i18n.t("side_bar.gold_transaction_history"),
        type: "link",
      },
      {
        path: "/gold-management/user-analysis",
        title: i18n.t("side_bar.gold_user_analysis"),
        type: "link",
      },
    ],
  },
  //#endregion

  //#region Message management
  {
    title: i18n.t("side_bar.message_management"),
    icon: "message-square",
    type: "sub",
    active: false,
    children: [
      {
        path: "/messages/send",
        title: i18n.t("side_bar.send_message"),
        type: "link",
      },
      {
        path: "/messages",
        title: i18n.t("side_bar.list_message"),
        type: "link",
      },
    ],
  },
  //#endregion

  //#region Channel list
  {
    title: i18n.t("channel_list"),
    icon: "tv",
    type: "link",
    active: false,
    path: "/pr-channels",
  },
  //#endregion

  //#region Announces
  {
    title: i18n.t("side_bar.announce"),
    icon: "message-square",
    type: "link",
    active: false,
    path: "/announces",
  },
  //#endregion

  //#region Pages
  {
    title: i18n.t("page_pages.page_title"),
    icon: "layout",
    type: "link",
    active: false,
    path: "/pages",
  },
  //#endregion

  //#region Email & SMS
  {
    title: i18n.t("side_bar.email_sms"),
    icon: "mail",
    type: "sub",
    active: false,
    children: [
      {
        title: i18n.t("page_email_templates.page_title"),
        type: "link",
        active: false,
        path: "/email-templates",
      },
      {
        title: i18n.t("page_sms_management.page_title"),
        type: "link",
        active: false,
        path: "/sms-management",
      },
      {
        title: i18n.t("page_merge_tag.page_title"),
        type: "link",
        active: false,
        path: "/email-sms/merge-tags",
      },
    ],
  },
  //#endregion

  //#region Languages Setting
  {
    title: i18n.t("page_languages.page_title"),
    icon: "globe",
    type: "sub",
    active: false,
    children: [
      {
        title: i18n.t("page_language_add.page_title"),
        icon: "file",
        type: "link",
        active: false,
        path: "/language/add",
      },
      {
        title: i18n.t("page_language_edit.page_title"),
        icon: "file",
        type: "link",
        active: false,
        path: "/language/edit",
      },
      {
        title: i18n.t("page_keywords_management.page_title"),
        icon: "file",
        type: "link",
        active: false,
        path: "/language/keywords",
      },
    ],
  },
  //#endregion

  //#region Sticker
  {
    title: i18n.t("side_bar.sticker"),
    icon: "smile",
    type: "sub",
    active: false,
    children: [
      {
        title: i18n.t("side_bar.list_sticker"),
        icon: "smile",
        type: "link",
        active: false,
        path: "/stickers",
      },
      {
        title: i18n.t("side_bar.add_sticker"),
        icon: "smile",
        type: "link",
        active: false,
        path: "/sticker/add",
      }
    ],
  },
  //#endregion

  //#region clothes
  {
    title: i18n.t("side_bar.clothes"),
    icon: "shopping-bag",
    type: "sub",
    active: false,
    children: [
      {
        title: i18n.t("side_bar.list_clothes"),
        icon: "shopping-bag",
        type: "link",
        active: false,
        path: "/clothes",
      },
      {
        title: i18n.t("side_bar.add_clothes"),
        icon: "shopping-bag",
        type: "link",
        active: false,
        path: "/clothes/add",
      },
      {
        title: i18n.t("side_bar.add_set_clothes"),
        icon: "shopping-bag",
        type: "link",
        active: false,
        path: "/set-clothes/add",
      }
    ],
  },
  //#endregion


  //#region Rooms
  {
    title: i18n.t("side_bar.rooms"),
    icon: "package",
    type: "sub",
    active: false,
    children: [
      {
        path: "/rooms",
        title: i18n.t("side_bar.list_rooms"),
        type: "link",
      },
      {
        path: "/sample-rooms",
        title: i18n.t("side_bar.list_sample_rooms"),
        type: "link",
      },
      // {
      //   path: "/rooms/add",
      //   title: i18n.t("side_bar.add_room"),
      //   type: "link",
      // },
      // {
      //   path: "/rooms/statistics",
      //   title: i18n.t("side_bar.statistics"),
      //   type: "link",
      // },
      {
        path: "/rooms/keyword",
        title: i18n.t("side_bar.keyword"),
        type: "link",
      },
    ],
  },
  //#endregion

  //#region Object
  {
    title: i18n.t("side_bar.objects"),
    icon: "box",
    type: "sub",
    active: false,
    children: [
      {
        title: i18n.t("side_bar.list_objects"),
        icon: "file",
        type: "link",
        active: false,
        path: "/objects",
      },
      // {
      //   title: i18n.t("side_bar.add_objects"),
      //   icon: "file",
      //   type: "link",
      //   active: false,
      //   path: "/objects/add",
      // },
      {
        title: i18n.t("side_bar.list_categories"),
        icon: "file",
        type: "link",
        active: false,
        path: "/categories",
      },
      // {
      //   title: i18n.t("side_bar.add_category"),
      //   icon: "file",
      //   type: "link",
      //   active: false,
      //   path: "/categories/add",
      // },
    ],
  },
  //#endregion

  //#region Permissions
  {
    title: i18n.t("side_bar.operator_management"),
    icon: "user",
    type: "sub",
    active: false,
    children: [
      {
        title: i18n.t("side_bar.operator_group"),
        icon: "file",
        type: "link",
        active: false,
        path: "/operator-group",
      },
      {
        title: i18n.t("side_bar.operator"),
        icon: "file",
        type: "link",
        active: false,
        path: "/operator",
      },
    ],

  },
  //#endregion
];
